<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-layout wrap>
            <v-flex xs12>
                <v-text-field label="Identificador" v-model="item.name" :rules="[rules.required]" :disabled="item.version != null" />
            </v-flex>
            <v-flex xs12>
                <v-select v-model="item.connection" :rules="[rules.required]" :items="connections" item-value="id" item-text="name" label="Conexão" />
            </v-flex>
            <v-flex xs12>
                <v-text-field label="Versão" v-model="item.version" disabled />
            </v-flex>
            <v-flex xs12>
                <v-select v-model="item.lastVersion" :items="versions" label="Ir para versão" />
            </v-flex>
            <v-flex xs12>
                <v-switch v-model="item.needRestart" label="Pedir para placa reiniciar" class="my-0 py-0" />
            </v-flex>
            <v-flex xs12>
                <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate" style="width: 100%;">Salvar</v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import connections from "@/assets/json/connections.json";
import versions from "@/assets/json/versions.json";
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            default: true,
        },
        controlAPI: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            connections,
            versions,
            itemClean: {
                name: "",
                connection: "",
                version: null,
                lastVersion: "",
                needRestart: false,
            },
            item: { ...this.itemClean },
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                if (newVal == null) {
                    this.item = { ...this.itemClean };
                } else {
                    this.item = { ...newVal };
                }
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                if (this.controlAPI == true) {
                    if (this.item.id) {
                        this.$http
                            .put(`api/v2/board`, this.item.id, this.item)
                            .then(() => {
                                this.$eventHub.$emit("msgSuccess", "Editado com sucesso.");
                                this.$emit("save", this.item);
                            })
                            .catch(() =>
                                this.$eventHub.$emit("msgError", "Erro ao salvar informações."));
                    } else {
                        this.$http
                            .post(`api/v2/board`, this.item)
                            .then(() => {
                                this.$eventHub.$emit("msgSuccess", "Salvo com sucesso.");
                                this.$emit("save", this.item);
                            })
                            .catch(() =>
                                this.$eventHub.$emit("msgError", "Erro ao salvar informações."));
                    }
                } else {
                    this.$emit("save", this.item);
                }
            }
        },
    },
};
</script>